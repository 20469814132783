// Component generated with util/vox-create-component.js
import React from "react";
import R from "ramda";
import {FormImpactWrapper} from "./FormImpact.styled";
import {FormImpactProps, FormImpactSchema} from "./FormImpact.types";
import {useForm, useFieldArray} from "react-hook-form";
import {string, SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "./../../component";
import * as U from "./../../ui";
import {Asset, ResponseGetAsset} from "../../types/asset";
import {getAssetListByType} from "../../service/api/assets-api";
import {
	persistKaleidoscope,
	downloadFile,
	getImageDownloadLink,
	getKaleidoscopeList,
	getKaleidoscopeById,
} from "../../service/api/impact-api";
import {Kaleidoscope, ResponseImpact} from "../../types/impact";
import {Document} from "../../types/document";

const FormImpactResolver: SchemaOf<FormImpactSchema> = object().shape({
	asset: string().required(),
	title: string().required(),
	subtitle: string().optional(),
	text: string().optional(),
	videoId: string().optional(),
	imageFile: mixed().optional(),
	imageId: mixed().optional(),
	id: string().optional(),
});

export const FormImpact: React.VFC<FormImpactProps> = ({...args}) => {
	// Context Here
	// States Here
	const [list, setList] = React.useState<any>([]);
	const [mediaList, setMediaList] = React.useState<any>([]);
	const [assetComboList, setAssetComboList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	// const [currentDelete, setCurrentDelete] = React.useState<any>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);
	const [currentError, setCurrentError] = React.useState<boolean>(false);
	// Hooks
	const {
		control,
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormImpactSchema>({
		resolver: yupResolver(FormImpactResolver),
		mode: "onSubmit",
	});

	const {
		fields: fieldsImg,
		append: appendImg,
		remove: removeImg,
	} = useFieldArray({
		control,
		name: "imageFile",
	});
	// Effects Here
	React.useEffect(() => {
		/**
		 * Aqui inicializamos o table com informacoes que podem ser trazidas de uma chamada api rest,
		 * em este caso estou utilizando valores mocados para simular
		 * Utilizaremos o state setList para clonar o mock
		 */
		(async () => {
			const assetListParam = await handleAssetList();
			await handleImpactList(assetListParam);
			setLoadingData(false);
		})();
	}, []);
	// Handlers Here

	const handleAssetList = async (): Promise<any> => {
		setLoadingData(true);
		const response: ResponseGetAsset = await getAssetListByType({type: "BUSINESS"});
		if (response.status !== "success") {
			console.error("Error", response.message);
			setCurrentError(true);
			setLoadingData(false);
			return;
		}
		const assetListResult = response.message as Asset[];
		const resultList = assetListResult.map((asset: Asset) => {
			return {
				id: asset.identity?.type + "#" + asset.identity?.id,
				label: asset.name,
				value: asset.identity?.type + "#" + asset.identity?.id,
			};
		});
		setAssetComboList(resultList);
		return resultList;
	};

	const handleImpactList = async (assetListParam: {id: string; label: string; value: string}[]) => {
		const impactResponse = await getKaleidoscopeList();
		if (impactResponse.status !== "success") {
			console.error("Error", impactResponse.message);
			setList([]);
			return;
		}
		const impactList = impactResponse.message as Kaleidoscope[];
		const listTmp: any = [];
		for (const impactIt of impactList) {
			const assetTmp = assetListParam.filter((asset: any) => {
				if (asset?.id) {
					const assetId = asset.id.split("#")[1];
					if (assetId === impactIt.asset.id) {
						return asset;
					}
				}
			})[0];
			listTmp.push({
				assetName: assetTmp.label,
				assetId: assetTmp.id.split("#")[1],
				id: impactIt.id,
				title: impactIt.title ? impactIt.title : "",
				subtitle: impactIt.subtitle ? impactIt.subtitle : "",
				text: impactIt.text ? impactIt.text : "",
				hasVideo: impactIt.videoId ? "Sim" : "Não",
				hasImage: impactIt.imageId && impactIt.imageId.length > 0 ? "Sim" : "Não",
				createdAt: impactIt.createdAt ? impactIt.createdAt : "",
			});
		}
		setList(listTmp);
	};
	const downloadImage = async (row: any) => {
		setLoading(true);
		try {
			const downloadInfo = await getImageDownloadLink(row.values.imageId);
			const documentInfo = downloadInfo as Document;
			if (documentInfo.name && documentInfo.fileUrl) {
				const response = await downloadFile(documentInfo.fileUrl);
				const objectURL = URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = objectURL;
				link.setAttribute("download", documentInfo.name + "." + documentInfo.fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setLoading(false);
		} catch (err: any) {
			console.error("Error on dowloading file", err.message);
			setCurrentError(true);
		}
	};

	const clearForm = () => {
		// Adicionar os inputs para reiniciar aqui
		reset({
			asset: "",
			title: "",
			subtitle: "",
			text: "",
			videoId: "",
			imageFile: [],
			imageId: [],
			id: "",
		});
	};
	const onEdit = async (row: any) => {
		try {
			const response: ResponseImpact = await getKaleidoscopeById({
				id: row.values.id,
			});
			console.log("response edit", response);
			if (response.status !== "success") {
				console.error("Error on getting impact by id: ", response.message);
				throw new Error("Error on getting impact by id");
			}
			const impact = response.message as Kaleidoscope;
			if (impact.imageId && impact.imageId.length > 0) {
				setMediaList(
					impact.imageId.map((imageId: string) => {
						return {imageId};
					}),
				);
			} else {
				setMediaList([]);
			}
			reset({
				asset: "BUSINESS#" + row.values.assetId,
				title: row.values.title,
				subtitle: row.values.subtitle,
				text: row.values.text,
				imageId: impact.imageId ? impact.imageId : [],
				videoId: impact.videoId ? impact.videoId[0] : "",
				imageFile: [],
				id: row.values.id,
			});

			trigger();
			toogle();
		} catch (err: any) {
			console.error("Error: ", err.message);
			setCurrentError(true);
			return;
		}
	};
	// const onDelete = (row: any) => setCurrentDelete(row);
	const onAdd = () => {
		clearForm();
		toogle();
	};
	const toogle = () => {
		setShowForm(!showForm);
	};
	// const deleteItem = async () => {
	// 	setLoading(true);
	// 	// Chamar o rest api para salvar as informações
	// 	const impact: Impact = {
	// 		asset: {
	// 			id: currentDelete.original.assetId,
	// 			type: currentDelete.original.assetType,
	// 		},
	// 		id: currentDelete.original.id,
	// 		title: "",
	// 	};
	// 	const result = await removeImpact(impact);
	// 	if (result.status !== "success") {
	// 		console.error("Error on remove", result.message);
	// 		setCurrentError(true);
	// 		setCurrentDelete(null);
	// 		setLoading(false);
	// 		return;
	// 	}
	// 	setCurrentDelete(null);
	// 	setLoading(false);
	// 	await handleImpactList(assetComboList);
	// };
	const onSubmit = handleSubmit(async (data) => {
		const impact: Kaleidoscope = {
			asset: {
				id: data.asset?.split("#")[1] || "",
			},
			id: data.id,
			title: data.title,
			subtitle: data.subtitle,
			text: data.text ? data.text : "",
			videoId: [data.videoId] || [],
			imageId: data.imageId || [],
			imageFile: (data.imageFile || []).map((file: any) => file.keyValue.item(0)),
		};
		setLoading(true);
		let response: ResponseImpact;
		response = (await persistKaleidoscope(impact)) as ResponseImpact;
		if (response.status !== "success") {
			console.error("Error: ", response.message);
			setCurrentError(true);
			return;
		}
		await handleImpactList(assetComboList);
		setLoading(false);
		toogle();
	});
	// Component
	return (
		<FormImpactWrapper {...args}>
			{/* {currentDelete && (
				<C.CPNAlert
					isModal
					title="Eliminar"
					description="Tem certeza que deseja eliminar este item?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDelete(null)}
					onAccept={() => !loading && deleteItem()}
				/>
			)} */}
			{currentError && (
				<C.CPNAlert
					isModal
					title="Erro interno"
					description="Aconteceu um erro na execução da operação."
					type="ERROR"
					loading={false}
					onAccept={() => {
						setLoading(false);
						setCurrentError(false);
					}}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className="title mb-4 flex flex-col pb-2">
					<U.UIText preset="HEADLINE_05">Impacto</U.UIText>
					<U.UIText preset="BODY">Criação e alteração das histórias de impacto.</U.UIText>
				</div>
				<div className={showForm ? "hidden" : "action"}>
					<U.UIButton icon="addCircle" label="Adicionar" outline onClick={onAdd} />
				</div>
			</div>
			<div className={!showForm ? "hidden" : "forms"}>
				<div className="form-container">
					<div className="form">
						<div className="input">
							<C.CPNSelectInput
								label="Ativo"
								options={assetComboList} // Add here ll options nedded
								type="SIMPLE" // Single check or multicheck
								error={errors.asset?.message}
								{...register("asset")}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput label="Título" error={errors.title?.message} {...register("title")} />
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Subtítulo (opcional)"
								error={errors.subtitle?.message}
								{...register("subtitle")}
							/>
						</div>
					</div>
					<div className="form">
						<div className="input">
							<C.CPNTextInput
								label="Video (opcional)"
								disabled={loading}
								error={errors.videoId?.message}
								{...register("videoId")}
							/>
						</div>
						<div className="input free w-full">
							<C.CPNTextarea
								label="Texto (opcional)"
								maxLength={10000}
								disabled={loading}
								error={errors.text?.message}
								{...register("text")}
							/>
						</div>
					</div>
					<div className="form">
						<div className="input group">
							<U.UIText preset="BODY_02">Imagens da história</U.UIText>
							{fieldsImg.map((field, index) => (
								<div className="control" key={field.id}>
									<C.CPNTextInput
										error={errors.imageFile?.message}
										type="file"
										placeholder="Upload imagem"
										disabled={loading}
										{...register(`imageFile.${index}.keyValue`)}
									/>
									<U.UIButton
										disabled={loading}
										icon="trash"
										preset="LINK"
										onClick={() => removeImg(index)}
									/>
								</div>
							))}
							<div className="action">
								<U.UIButton
									preset="LINK"
									icon="addCircle"
									onClick={() => appendImg({})}
									label="Adicionar imagem"
								/>
							</div>
							<U.UIText preset="BODY_03" color="GREY">
								Adicione as imagens que irão aparecer na história
							</U.UIText>
						</div>
						<div className="input">
							<C.CPNTextInput
								label="Identificador"
								error={errors.id?.message}
								{...register("id")}
								disabled={true}
							/>
						</div>
					</div>
					{/* TODO: Adicionar preview */}
					{/* <div className="preview">
						<div className="container">
							<div className="data">
								<U.UIText preset="HEADLINE_06" color="PRIMARY">
									Ailton: Estudando para residência
								</U.UIText>
								<U.UIText preset="HEADLINE">
									“Eu jamais teria sido aprovado se não fosse pela Sanar”{" "}
								</U.UIText>
								<U.UIText>
									A Sanar é uma empresa investida pelo fundo que diariamente impact a vida de
									milhares de alunos como o Ailton
								</U.UIText>
							</div>
							<div className="media">
								<div className="images">
									<U.UIText preset="BODY_02" color="GREY">
										Imágens
									</U.UIText>
									<div className="content">
										<div
											className="media-img"
											style={{
												backgroundImage: `url(${"https://images.unsplash.com/photo-1644827607243-bc73b472ebb7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80"})`,
											}}
										/>
										<div
											className="media-img"
											style={{
												backgroundImage: `url(${"https://images.unsplash.com/photo-1644827607243-bc73b472ebb7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80"})`,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<div className="action gap-4">
					<U.UIButton label="Cancelar" outline loading={loading} onClick={toogle} />
					<U.UIButton label="Enviar" loading={loading} onClick={onSubmit} />
				</div>
				{!mediaList.length ? (
					<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
						<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
					</div>
				) : (
					<C.CPNTable
						onDownload={downloadImage}
						columns={[
							{
								Header: "Imagens",
								accessor: "imageId",
							},
						]}
						data={mediaList}
					/>
				)}
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, porfavor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							columns={[
								{
									Header: "Nome do Ativo",
									accessor: "assetName",
								},
								{
									Header: "Id do Ativo",
									accessor: "assetId",
								},
								{
									Header: "Id",
									accessor: "id",
								},
								{
									Header: "Título",
									accessor: "title",
								},
								{
									Header: "Subtítulo",
									accessor: "subtitle",
								},
								{
									Header: "Texto",
									accessor: "text",
								},
								{
									Header: "Contém Vídeo",
									accessor: "hasVideo",
								},
								{
									Header: "Contêm Imagens",
									accessor: "hasImage",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormImpactWrapper>
	);
};

export default FormImpact;

import * as React from "react";
import * as U from "./../../../ui";
import * as C from "./../../../component";
import {RegisterContext} from "./../../../context/RegisterContext";
import {FormOtherDocumentsSchema} from "../FormRegister.types";
import {useForm} from "react-hook-form";
import {useParams, useHistory, useLocation} from "react-router-dom";
import {SchemaOf, object, mixed, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {saveFile, putRegister} from "../../../service/api/registry-api";
import {downloadDocumentById, downloadFile} from "../../../service/api/doc-api";
import {Document} from "../../../types/document";

const FormOtherDocumentsResolver: SchemaOf<FormOtherDocumentsSchema> = object().shape({
	name: string().required("O nome do arquivo é necessario"),
	file: mixed().required("Selecione um arquivo"),
});

const FormOtherDocuments: React.FC<any> = ({sendFormData, loading, visible}) => {
	const history = useHistory();
	const {registerid} = useParams<any>();
	const location = useLocation<any>();

	// Context
	const {state, dispatch} = React.useContext(RegisterContext);
	// States
	const [loadFile, setLoadFile] = React.useState<boolean>(false);
	const [listDoc, setListDoc] = React.useState<any>(
		location?.state?.documents || state?.formDocuments || [],
	);
	const [download, setdownload] = React.useState<boolean>(false);
	// Form
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors},
	} = useForm<FormOtherDocumentsSchema>({
		resolver: yupResolver(FormOtherDocumentsResolver),
		mode: "onSubmit",
		defaultValues: {
			name: "",
			file: null,
		},
	});
	// Handlers
	const downloadCurrentFile = async (file: any) => {
		setdownload(true);
		try {
			const documentDownload: Document = await downloadDocumentById(file);
			const filename = documentDownload.name;
			const fileExtension = documentDownload.fileExtension;
			if (documentDownload.name && documentDownload.fileUrl) {
				const response = await downloadFile(documentDownload.fileUrl);
				const objectURL = URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = objectURL;
				link.setAttribute("download", filename + "." + fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (e) {
			alert(`Aconteceu um error na hora de baixar o arquivo: ${JSON.stringify(e)}`);
		}
		setdownload(false);
	};

	const submitForm = handleSubmit(async (data) => {
		try {
			if (data && data.file) {
				setLoadFile(true);
				const responseDoc = await saveFile(
					{...state?.formPersonalData, personId: state?.formPersonId},
					data,
				);
				const docResume: any = {
					name: data.name,
					id: responseDoc.id,
				};
				const newListDocs = [...listDoc, docResume];
				const request: any = {
					...{documentList: newListDocs},
					personId: registerid || state?.formPersonId,
				};
				if (sendFormData && !registerid) {
					sendFormData(request);
				} else if (registerid) {
					await putRegister({
						individual: {
							...request,
						},
						id: registerid,
					});
				}
				setLoadFile(false);
				dispatch({
					type: "SET_OTHER_DOCUMENTS",
					payload: {
						formDocuments: newListDocs,
					},
				});
				setListDoc(newListDocs);
				// Clear inputs
				reset({
					name: "",
					file: null,
				});
			} else if (registerid) {
			}
		} catch (error) {
			alert("Aconteceu um error tentando subir o arquivo");
		}
	});
	// Component
	return (
		<div className={`${visible || registerid ? "flex" : "hidden"} flex-col gap-4 h-full`}>
			{(download || loadFile) && <U.UILoading />}
			<div className="flex flex-col justify-start">
				{registerid && (
					<div>
						<U.UIButton preset="LINK" icon="arrowBack" label="Voltar" onClick={history.goBack} />
					</div>
				)}
				<U.UIText preset="HEADLINE_05">
					{registerid ? "Adicionar documentos" : " Outros documentos"}
				</U.UIText>
				{registerid && (
					<U.UIText preset="BODY_02" color="GREY">
						<U.UIText preset="BUTTON_02" color="GREY">
							Id do cotista:
						</U.UIText>{" "}
						{registerid}
					</U.UIText>
				)}
			</div>
			<div className="flex-1 flex flex-col gap-9">
				<div className="flex justify-end items-end gap-4">
					<div className="grid grid-cols-2 gap-4 flex-1">
						<div className="input">
							<C.CPNTextInput
								label="Nome Documento"
								disabled={loading || loadFile || loadFile || download}
								error={errors?.name?.message}
								{...register(`name`)}
							/>
						</div>
						<div className="input">
							<C.CPNTextInput
								type="file"
								label="Arquivo"
								placeholder="Upload documento"
								disabled={loading || loadFile || loadFile || download}
								error={errors?.file?.message}
								{...register(`file`)}
							/>
						</div>
					</div>
					<div className="action gap-4">
						<U.UIButton
							label="Enviar documento"
							disabled={loadFile || loading || loadFile}
							loading={loadFile || loading}
							onClick={submitForm}
						/>
					</div>
				</div>
				{listDoc.length > 0 && (
					<div className="flex flex-col gap-4 rounded border border-gray-light bg-gray-glow">
						<div className="pl-4 pt-4">
							<U.UIText preset="BUTTON_02" color="GREY">
								Documentos adicionados recentemente
							</U.UIText>
						</div>
						<div className="list">
							{listDoc.map((doc: any) => (
								<div
									className="px-4 py-2 border-t border-gray-light flex justify-between"
									key={doc.id}>
									<U.UIText preset="BUTTON">{doc.name}</U.UIText>
									<div className="cursor-pointer flex gap-1 items-center">
										<U.UIText
											preset="BUTTON"
											color="PRIMARY"
											onClick={() => (download ? {} : downloadCurrentFile(doc.id))}>
											Baixar documento
										</U.UIText>
										<U.UIICon name="download" color="PRIMARY" />
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FormOtherDocuments;

// Component generated with util/vox-create-component.js
import React from "react";
import {FormDocumentsWrapper} from "./FormDocuments.styled";
import {FormDocumentsProps, FormDocumentsSchema} from "./FormDocuments.types";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "./../../component";
import * as U from "./../../ui";
import {RequestDocument, ResponseDocument, Document} from "../../types/document";
import {
	getDocumentList,
	removeDocumentById,
	downloadDocumentById,
	postDocument,
	putDocument,
	downloadFile,
} from "../../service/api/doc-api";

const FormDocumentsResolver: SchemaOf<FormDocumentsSchema> = object().shape({
	id: string().required(),
	fileName: string(),
	date: string().required(),
	type: string().required(),
	file: mixed().required(),
	fileExtension: string(),
	createdAt: string().optional(),
});

export const FormDocuments: React.VFC<FormDocumentsProps> = ({...args}) => {
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentDelete, setCurrentDelete] = React.useState<any>(null);
	const [currentEdit, setCurrentEdit] = React.useState<boolean>(false);
	const [currentError, setCurrentError] = React.useState<boolean>(false);
	const [currentDownload, setCurrentDownload] = React.useState<any>(null);
	const [downloading, setDownloading] = React.useState(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);

	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormDocumentsSchema>({
		resolver: yupResolver(FormDocumentsResolver),
		mode: "onSubmit",
	});

	React.useEffect(() => {
		setLoadingData(true);
		getDocumentListHandler();
	}, []);

	const getDocumentListHandler = (): any => {
		getDocumentList()
			.then((result: ResponseDocument) => {
				const resultList: Document[] = Array<Document>();
				const documentList = result.message as Document[];
				documentList.forEach((element: Document) => {
					if (element.path && !["common", "fund"].includes(element.path)) {
						return;
					}
					const document: Document = {
						fileExtension: element.fileExtension ? element.fileExtension : "",
						type: element.path ? element.path : "",
						name: element.name ? element.name : "",
						id: element.id,
						createdAt: element.createdAt
							? new Date(element.createdAt).toLocaleDateString("pt-BR")
							: "",
						date: element.date,
					};
					resultList.push(document);
				});
				setList(resultList);
				setLoadingData(false);
			})
			.catch((reason: any) => {
				return () => setList([]);
			});
	};

	const clearForm = () =>
		reset({
			type: "",
			fileName: "",
			file: "",
			date: "",
			id: "0",
		});

	const onEdit = (row: any) => {
		console.log("onEdit: ", row);
		setLoading(false);
		clearForm();
		reset({
			fileName: row.values.name,
			date: row.values.date,
			type: row.values.type,
			file: "/foo",
			id: row.values.id,
		});
		setCurrentEdit(true);
		trigger();
		toogle();
	};

	const onDelete = (row: any) => setCurrentDelete(row);

	const onDownload = (row: any) => {
		if (!row?.values?.id) {
			return;
		}
		printStatement(row.values.id);
	};

	const onAdd = () => {
		clearForm();
		setCurrentEdit(false);
		trigger();
		toogle();
	};

	const toogle = () => {
		setShowForm(!showForm);
	};

	const deleteItem = async () => {
		setLoading(true);
		const id = currentDelete.original.id as string;
		removeDocumentById(id).then((response: ResponseDocument) => {
			setCurrentDelete(null);
			setLoading(false);
			if (response.status !== 200) {
				console.log(response.message);
			}
			getDocumentListHandler();
		});
		setCurrentDelete(null);
		setLoading(false);
	};

	const onSubmit = handleSubmit(async (data: any) => {
		setLoading(true);
		const request: RequestDocument = {
			document: {
				fileExtension: data.fileExtension,
				name: data.fileName,
				id: data.id,
				type: data.type,
				file: data.file,
				date: data.date,
			},
			id: data.id,
		};
		try {
			if (data.id !== "0") {
				await putDocument(request);
			} else {
				await postDocument(request);
			}
			setLoading(false);
			await getDocumentListHandler();
			toogle();
		} catch (err) {
			console.error("Error on submit", err);
			setCurrentError(true);
		}
	});

	const printStatement = async (file: any) => {
		setDownloading(true);
		try {
			const documentDownload: Document = await downloadDocumentById(file);
			const filename = documentDownload.name;
			const fileExtension = documentDownload.fileExtension;
			if (documentDownload.name && documentDownload.fileUrl) {
				const response = await downloadFile(documentDownload.fileUrl);
				const objectURL = URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = objectURL;
				link.setAttribute("download", filename + "." + fileExtension);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (e) {
			console.error(`Error on download ${e}`);
			setCurrentError(true);
		} finally {
			setDownloading(false);
		}
	};

	return (
		<FormDocumentsWrapper {...args}>
			{currentDownload && (
				<C.CPNAlert
					isModal
					title="ERROR"
					description="Erro ao fazer o download do documento."
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDownload(null)}
				/>
			)}
			{currentDelete && (
				<C.CPNAlert
					isModal
					title="Eliminar"
					description="Tem certeza que deseja eliminar este item?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDelete(null)}
					onAccept={() => !loading && deleteItem()}
				/>
			)}
			{currentError && (
				<C.CPNAlert
					isModal
					title="Erro interno"
					description="Aconteceu um erro na execução da operação."
					type="ERROR"
					loading={false}
					onAccept={() => {
						setLoading(false);
						setCurrentError(false);
					}}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className={showForm ? "hidden" : "title mb-4 flex flex-col pb-2"}>
					<U.UIText preset="HEADLINE_05">Lista de documentos</U.UIText>
					<U.UIText preset="BODY">
						Lista de documentos da Vox, de fundos, de ativos dentre outros.
					</U.UIText>
				</div>
				<div className={showForm ? "hidden" : "action"}>
					<U.UIButton icon="addCircle" label="Adicionar" outline onClick={onAdd} />
				</div>
			</div>
			{showForm && (
				<div className="forms">
					<div className="title mb-4 flex flex-col pb-2">
						<U.UIText preset="HEADLINE_05">Upload Documentos</U.UIText>
						<U.UIText preset="BODY">
							Atenção: Por favor, nomeie o arquivo de acordo com AnoMêsDia_Informação_Empresa e/ou
							Fundo referente
						</U.UIText>
					</div>
					<div className="form">
						{currentEdit && (
							<div className="input">
								<C.CPNTextInput
									label="Nome"
									error={errors.fileName?.message}
									{...register("fileName")}
								/>
							</div>
						)}
						<div className="input">
							<C.CPNDatePicker
								label="Data do Arquivo"
								error={errors.date?.message}
								{...register("date")}
							/>
						</div>
						<div className="input">
							<C.CPNSelectInput
								label="Tipo"
								disabled={currentEdit}
								options={[
									{label: "Fundo", id: 1, value: "fund"},
									{label: "Gestora Vox", id: 2, value: "common"},
								]}
								type="SIMPLE"
								error={errors.type?.message}
								{...register("type")}
							/>
						</div>
						{!currentEdit && (
							<div className="input">
								<C.CPNTextInput
									label="Upload documento"
									type="file"
									placeholder="Upload documento"
									help="Faça o upload do documento"
									error={errors.file?.message}
									disabled={loading}
									{...register("file")}
								/>
							</div>
						)}
					</div>
					<div className="action gap-4">
						<U.UIButton label="Cancelar" outline loading={loading} onClick={toogle} />
						<U.UIButton label="Enviar" loading={loading} onClick={onSubmit} />
					</div>
				</div>
			)}
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, por favor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							onDownload={onDownload}
							columns={[
								{
									Header: "Id",
									accessor: "id",
								},
								{
									Header: "Nome",
									accessor: "name",
								},
								{
									Header: "Extensão",
									accessor: "fileExtension",
								},
								{
									Header: "Tipo",
									accessor: "type",
								},
								{
									Header: "Data upload",
									accessor: "createdAt",
								},
								{
									Header: "Data Arquivo",
									accessor: "date",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormDocumentsWrapper>
	);
};

export default FormDocuments;

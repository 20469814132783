import {ResponseDocument} from "../../types/document";
import {FundProduct, ResponseFundProduct} from "./../../types/fund";
import {HTTP, axios} from "./../http";

const BASE_FUNDS_URL = "/funds";
const BASE_DOC_URL = `/documents`;

export const getAllProductsFund = async (): Promise<ResponseFundProduct> => {
	const result = await HTTP.get(`${BASE_FUNDS_URL}/product`);
	return result.data as ResponseFundProduct;
};

export const getProductFundById = async (idProduct: string): Promise<ResponseFundProduct> => {
	const result = await HTTP.get(`${BASE_FUNDS_URL}/product/${idProduct}`);
	return result.data as ResponseFundProduct;
};

export const getClientIdByPersonId = async (personId: string): Promise<ResponseFundProduct> => {
	const result = await HTTP.get(`${BASE_FUNDS_URL}/backofficeClient/${personId}`);
	return result.data as ResponseFundProduct;
};

export const patchBackofficeClient = async (props: any): Promise<any> => {
	console.log("RequestRegister: ", props);
	const result: any = await HTTP.patch(`${BASE_FUNDS_URL}/backofficeClient/`, props);
	return result?.data?.message;
};

export const postFundToUser = async (
	personId: string,
	fundId: string,
): Promise<ResponseFundProduct> => {
	const result = await HTTP.post(`${BASE_FUNDS_URL}/position`, {
		personId,
		fundId,
	});
	return result.data as any;
};

export const getProductFundByPersonId = async (
	idProduct: string,
): Promise<ResponseFundProduct | null> => {
	try {
		const result = await HTTP.get(`${BASE_FUNDS_URL}/position/${idProduct}`);
		return result.data as ResponseFundProduct;
	} catch {
		return null;
	}
};

export const putProductFund = async (fund: FundProduct): Promise<ResponseFundProduct> => {
	if (fund?.imageFile && fund.imageFile[0]) {
		const {id, filePath} = await uploadFile(fund.imageFile[0], true);
		fund.image = id;
		fund.imagePath = filePath;
	}

	const result = await HTTP.put(`${BASE_FUNDS_URL}/product/`, fund);
	return result.data as ResponseFundProduct;
};

export const createProductFund = async (fund: FundProduct): Promise<ResponseFundProduct> => {
	if (fund?.imageFile && fund.imageFile[0]) {
		const {id, filePath} = await uploadFile(fund.imageFile[0], true);
		fund.image = id;
		fund.imagePath = filePath;
	}

	const result = await HTTP.post<FundProduct, any>(`${BASE_FUNDS_URL}/product/`, fund);
	return result.data as ResponseFundProduct;
};

export const patchProductFund = async (fund: FundProduct): Promise<ResponseFundProduct> => {
	const result = await HTTP.patch(`${BASE_FUNDS_URL}/product/`, fund);
	return result.data as ResponseFundProduct;
};

export const consolidateProductFund = async (idProduct: string): Promise<ResponseFundProduct> => {
	const result = await HTTP.patch(`${BASE_FUNDS_URL}/fund/product/${idProduct}/history`);
	return result.data as ResponseFundProduct;
};

const uploadFile = async (
	file: File,
	isPublic = false,
): Promise<{id: string; filePath: string}> => {
	try {
		let responseDocument: ResponseDocument | any = null;
		const fileNameSplit = file.name.split(".");
		const fileExtension = fileNameSplit[fileNameSplit.length - 1];
		const name = file.name.replace(`.${fileExtension}`, "");
		const documentType = "fund/images";
		const contentType = file.type;
		const bodyUpload = {
			name,
			fileExtension,
			documentType,
			contentType,
			isPublic,
		};
		const result = await HTTP.post<any>(`${BASE_DOC_URL}`, bodyUpload);
		responseDocument = result.data as ResponseDocument;
		if (!responseDocument.fileUrl) {
			console.log("Error on generate file url", responseDocument);
			throw new Error("Something went wrong on file upload.");
		}
		// notice: axios directly because aws s3 url interceptor Authorization header error
		const resultUpload = await axios({
			method: "put",
			url: responseDocument.fileUrl,
			data: file,
			headers: {"Content-Type": file.type},
		});
		if (resultUpload.status !== 200) {
			console.error("Error on file upload", resultUpload.data);
			throw new Error("Something went wrong on file upload.");
		}
		const resultStatus = await HTTP.patch<any>(`${BASE_DOC_URL}/${responseDocument.id}`, {
			status: "ACTIVE",
		});
		if (resultStatus.status !== 200) {
			console.error("Error on updating file upload status", resultStatus.data.message);
			throw new Error("Somethin went wrong on file upload.");
		}
		return {id: responseDocument.id, filePath: responseDocument.filePath};
	} catch (error) {
		throw error;
	}
};

export const getImageDownloadLink = async (imageId: string): Promise<any> => {
	const result = await HTTP.get<any>(`${BASE_DOC_URL}/url/adm/${imageId}`);
	if (result.status !== 200) {
		console.error("Error status code", result.status);
		throw new Error("Something went wrong");
	}
	return result.data;
};

export const downloadFile = async (fileUrl: string): Promise<any> => {
	// notice: axios directly because aws s3 url interceptor Authorization header error
	const downloadResult = await axios.get(fileUrl, {responseType: "blob"});
	return downloadResult;
};

export const getQuarterlyReportFund = async (
	idProduct: string,
	date: string,
): Promise<any | null> => {
	try {
		const result = await HTTP.patch(`${BASE_FUNDS_URL}/quarterlyReport/`, {
			fundId: idProduct,
			date,
		});
		return result.data;
	} catch {
		return null;
	}
};

export const getHistoryCalcBondFund = async (
	idProduct: string,
	bondId: string,
): Promise<any | null> => {
	try {
		const result = await HTTP.get(`${BASE_FUNDS_URL}/historyCalc/${idProduct}/bond/${bondId}`);
		return result.data;
	} catch {
		return null;
	}
};

export const getHistoryCalcByFundId = async (idProduct: string): Promise<any> => {
	const result = await HTTP.get(`${BASE_FUNDS_URL}/historyCalc/${idProduct}/bond`);
	return result.data;
};

// Component generated with util/vox-create-component.js
import React, {useState} from "react";
import * as R from "ramda";
import {TableWrapper, PaginationWrapper, CommonTh, ActionTh, CommonTd} from "./Table.styled";
import {TableProps} from "./Table.types";
import {TableFilter} from "./TableFilter";
import {useTable, useSortBy, usePagination, useFilters, useGlobalFilter} from "react-table";
import {UIButton, UIICon, UIText} from "../../ui";

import uniqid from "uniqid";

export const Table: React.VFC<TableProps> = ({
	loading,
	columns,
	data,
	onEdit,
	onDownload,
	onDelete,
	onConsolidate,
	itemsPerPage = 20,
	pagesLimit = 3,
	...args
}) => {
	// Context Here
	// States Here
	const [currentbreak, setCurrentbreak] = useState(1);
	// Effects Here
	// Handlers Here
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: {pageIndex, globalFilter},
	} = useTable(
		{
			columns,
			data,
			initialState: {pageIndex: 0, pageSize: itemsPerPage},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
	);
	// Constants
	const allBreaks = Math.ceil((pageCount - 2) / pagesLimit);

	const getPageOptions = () => {
		const pages: any[] = [];
		const breakIndex = currentbreak * pagesLimit - pagesLimit + 1;

		pages.push(
			<UIButton
				key={uniqid()}
				preset={!pageIndex ? "DEFAULT" : "SECONDARY"}
				label={`1`}
				onClick={() => {
					setCurrentbreak(1);
					gotoPage(0);
				}}
			/>,
		);

		if (currentbreak > 1) {
			pages.push(
				<UIButton
					key={uniqid()}
					preset={"SECONDARY"}
					label={`...`}
					onClick={() => {
						gotoPage(pagesLimit * currentbreak - 3);
						setCurrentbreak(currentbreak - 1);
					}}
				/>,
			);
		}
		for (let i = breakIndex; i <= currentbreak * pagesLimit; i++) {
			if (i < pageCount - 1) {
				pages.push(
					<UIButton
						key={uniqid()}
						preset={pageIndex === i ? "DEFAULT" : "SECONDARY"}
						label={`${i + 1}`}
						onClick={() => gotoPage(i)}
					/>,
				);
			}
		}
		if (currentbreak < allBreaks) {
			pages.push(
				<UIButton
					key={uniqid()}
					preset={"SECONDARY"}
					label={`...`}
					onClick={() => {
						gotoPage(breakIndex + pagesLimit);
						setCurrentbreak(currentbreak + 1);
					}}
				/>,
			);
		}
		pages.push(
			<UIButton
				key={uniqid()}
				preset={pageIndex === pageCount - 1 ? "DEFAULT" : "SECONDARY"}
				label={`${pageCount}`}
				onClick={() => {
					setCurrentbreak(allBreaks);
					gotoPage(pageCount - 1);
				}}
			/>,
		);
		return pages;
	};

	return (
		<TableWrapper {...args}>
			<div>
				<TableFilter
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={globalFilter}
					setGlobalFilter={setGlobalFilter}
				/>
			</div>
			<div className="table-container">
				<table
					className="rounded-t-lg overflow-hidden border border-gray-light rounded py-2"
					{...getTableProps()}>
					<thead className="bg-white">
						{headerGroups.map((headerGroup) => {
							const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
							return (
								<tr key={key} {...restHeaderGroupProps}>
									<th className="px-1" />
									{headerGroup.headers.map((column) => {
										const {key: columnKey, ...restColumn} = column.getHeaderProps(
											column.getSortByToggleProps(),
										);
										if (typeof column.render("Header") === "string") {
											return (
												<CommonTh key={columnKey} {...restColumn}>
													<div className="flex items-center font-bold hover:underline">
														<UIText preset="BODY_03" color="GREY">
															{column.render("Header")}
														</UIText>
														<div className="ml-1">
															<UIICon name="order" color="GREY" />
														</div>
													</div>
												</CommonTh>
											);
										}
										return <></>;
									})}
									{!onDownload || <ActionTh />}
									{!onConsolidate || <ActionTh />}
									{onEdit && <ActionTh />}
									{!onDelete || <ActionTh />}
									<th className="px-1" />
								</tr>
							);
						})}
					</thead>
					{loading ? (
						<tbody className="bg-white">
							<tr>
								<td className="p-4 border border-gray-light rounded-b-lg" colSpan={columns.length}>
									Trazendo informações, aguarde ...
								</td>
							</tr>
						</tbody>
					) : (
						<tbody className="bg-white" {...getTableBodyProps()}>
							<tr className="h-1" />
							{page.map((row: any, i) => {
								prepareRow(row);
								const {key: rowKey, ...restRowProps} = row.getRowProps();
								return (
									<tr
										key={rowKey}
										className="hover:bg-gray-smoke cursor-pointer py-0"
										{...restRowProps}
										onClick={() => (onEdit ? onEdit(row) : {})}>
										<td width={20} className="px-1 border-b border-gray-light" />
										{row.cells.map((cell: any) => {
											const {key: cellKey, ...restCellProps} = cell.getCellProps();
											if (typeof cell.render("Header") === "string") {
												return (
													<CommonTd key={cellKey} {...restCellProps}>
														<UIText preset="BODY_02" color="DARK">
															{cell.render("Cell")}
														</UIText>
													</CommonTd>
												);
											}
											return <></>;
										})}
										{!onDownload || (
											<CommonTd width={50}>
												<UIText
													preset="BODY_03"
													color="DARK"
													onClick={() => (onDownload ? onDownload(row) : {})}>
													Download
												</UIText>
											</CommonTd>
										)}
										{!onEdit || (
											<CommonTd width={50}>
												<UIText
													preset="BODY_03"
													color="DARK"
													onClick={() => (onEdit ? onEdit(row) : {})}>
													Editar
												</UIText>
											</CommonTd>
										)}
										{!onDelete || (
											<CommonTd width={50}>
												<UIText
													preset="BODY_03"
													color="DANGER"
													onClick={() => (onDelete ? onDelete(row) : {})}>
													Deletar
												</UIText>
											</CommonTd>
										)}
										{!onConsolidate || (
											<CommonTd width={50}>
												<UIText
													preset="BODY_03"
													color="DARK"
													onClick={() => (onConsolidate ? onConsolidate(row) : {})}>
													Consolidar
												</UIText>
											</CommonTd>
										)}
										<td width={20} className="px-1 border-b border-gray-light" />
									</tr>
								);
							})}
							{/* <tr className="h-2" /> */}
						</tbody>
					)}
				</table>
			</div>
			{pageCount && pageCount > 1 && (
				<PaginationWrapper>
					<UIButton
						disabled={!canPreviousPage}
						onClick={() => {
							setCurrentbreak(1);
							gotoPage(0);
						}}
						preset="LINK"
						label="<<"
					/>
					<UIButton
						disabled={!canPreviousPage}
						onClick={() => {
							if (pageIndex - 1 < currentbreak * pagesLimit - 2 && pageIndex > 1)
								setCurrentbreak(currentbreak - 1);
							previousPage();
						}}
						preset="LINK"
						label="Anterior"
					/>
					{getPageOptions()}
					<UIButton
						disabled={!canNextPage}
						onClick={() => {
							if (pageIndex + 1 > currentbreak * pagesLimit && allBreaks > 1)
								setCurrentbreak(currentbreak + 1);
							nextPage();
						}}
						preset="LINK"
						label="Próxima"
					/>
					<UIButton
						disabled={!canNextPage}
						onClick={() => {
							setCurrentbreak(allBreaks);
							gotoPage(pageCount - 1);
						}}
						preset="LINK"
						label=">>"
					/>
				</PaginationWrapper>
			)}
		</TableWrapper>
	);
};

export default Table;
